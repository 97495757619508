var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--products"
  }, [_c('main-header'), _vm._m(0), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('section', {
    staticClass: "product-mypet"
  }, [_c('h1', {
    staticClass: "product-mypet__title"
  }, [_vm._v("추천 상품")]), _c('div', {
    staticClass: "product-mypet-list product-mypet-list--1st"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, [_c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])])])]), _c('div', {
    staticClass: "main-products__prev"
  }), _c('div', {
    staticClass: "main-products__next"
  })])]), _c('section', {
    staticClass: "product-mypet"
  }, [_c('h1', {
    staticClass: "product-mypet__title"
  }, [_vm._v("추천 사료")]), _c('div', {
    staticClass: "product-mypet-list product-mypet-list--2nd"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, [_c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])])])]), _c('div', {
    staticClass: "main-products__prev"
  }), _c('div', {
    staticClass: "main-products__next"
  })])]), _c('section', {
    staticClass: "product-mypet"
  }, [_c('h1', {
    staticClass: "product-mypet__title"
  }, [_vm._v("추천 영양제")]), _c('div', {
    staticClass: "product-mypet-list product-mypet-list--3rd"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, [_c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])]), _c('ul', {
    staticClass: "product-list__tags"
  }, [_c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#강아지")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#사료")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("#츄르")]), _c('li', {
    staticClass: "product-list__tag"
  }, [_vm._v("# 300g")])])])])])]), _c('div', {
    staticClass: "main-products__prev"
  }), _c('div', {
    staticClass: "main-products__next"
  })])])])]);

}]

export { render, staticRenderFns }