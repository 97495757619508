<template>
	<div class="wrap wrap--products">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="container">
                <section class="product-mypet">
                    <h1 class="product-mypet__title">추천 상품</h1>
                    <div class="product-mypet-list product-mypet-list--1st">
                        <div class="swiper-container">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="main-products__prev"></div>
                        <div class="main-products__next"></div>
                    </div>
                </section>
                <section class="product-mypet">
                    <h1 class="product-mypet__title">추천 사료</h1>
                    <div class="product-mypet-list product-mypet-list--2nd">
                        <div class="swiper-container">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="main-products__prev"></div>
                        <div class="main-products__next"></div>
                    </div>
                </section>
                <section class="product-mypet">
                    <h1 class="product-mypet__title">추천 영양제</h1>
                    <div class="product-mypet-list product-mypet-list--3rd">
                        <div class="swiper-container">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="product-list" href="/shop/products/view">
                                        <span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
                                        <span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
                                        <span class="product-list__price">
                                            <span class="product-list__price-left">
                                                <em class="product-list__price-num">15,000</em>원
                                                <span class="product-list__price-percent">25%</span>
                                            </span>
                                        </span>
                                        <ul class="product-list__tags">
                                            <li class="product-list__tag">#강아지</li>
                                            <li class="product-list__tag">#사료</li>
                                            <li class="product-list__tag">#츄르</li>
                                            <li class="product-list__tag"># 300g</li>
                                        </ul>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="main-products__prev"></div>
                        <div class="main-products__next"></div>
                    </div>
                </section>
			</div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from "../../../components/client/inc/main-header.vue";
import MainFooter from "../../../components/client/inc/main-footer.vue";

export default{
	components: {
		MainHeader,
		MainFooter
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
            // 추천 상품
            var slide = new Swiper ('.product-mypet-list--1st .swiper-container', {
                allowTouchMove: false,
                spaceBetween : 0,
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: true,
                    disableOnInteraction: false,
                },
                slidesPerView: 4,
                slidesPerGroup: 4,
                speed: 300,
                loop: false,
                // pagination: {
                //     el: '.visual__pagination',
                //     clickable: true,
                // },
                navigation: {
                    nextEl: '.product-mypet-list--1st .main-products__next',
                    prevEl: '.product-mypet-list--1st .main-products__prev'
                },
            });


            // 추천 사료
            var slide = new Swiper ('.product-mypet-list--2nd .swiper-container', {
                allowTouchMove: false,
                spaceBetween : 0,
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: true,
                    disableOnInteraction: false,
                },
                slidesPerView: 4,
                slidesPerGroup: 4,
                speed: 300,
                loop: false,
                // pagination: {
                //     el: '.visual__pagination',
                //     clickable: true,
                // },
                navigation: {
                    nextEl: '.product-mypet-list--2nd .main-products__next',
                    prevEl: '.product-mypet-list--2nd .main-products__prev'
                },
            });


            // 추천 영양제
            var slide = new Swiper ('.product-mypet-list--3rd .swiper-container', {
                allowTouchMove: false,
                spaceBetween : 0,
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: true,
                    disableOnInteraction: false,
                },
                slidesPerView: 4,
                slidesPerGroup: 4,
                speed: 300,
                loop: false,
                // pagination: {
                //     el: '.visual__pagination',
                //     clickable: true,
                // },
                navigation: {
                    nextEl: '.product-mypet-list--3rd .main-products__next',
                    prevEl: '.product-mypet-list--3rd .main-products__prev'
                },
            });
		}
	},
}
</script>
